// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-experience-js": () => import("./../../../src/pages/about/experience.js" /* webpackChunkName: "component---src-pages-about-experience-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-index-pt-br-js": () => import("./../../../src/pages/blog/index.pt-br.js" /* webpackChunkName: "component---src-pages-blog-index-pt-br-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-br-js": () => import("./../../../src/pages/index.pt-br.js" /* webpackChunkName: "component---src-pages-index-pt-br-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-pt-br-js": () => import("./../../../src/pages/portfolio.pt-br.js" /* webpackChunkName: "component---src-pages-portfolio-pt-br-js" */),
  "component---src-pages-sobre-experiencia-pt-br-js": () => import("./../../../src/pages/sobre/experiencia.pt-br.js" /* webpackChunkName: "component---src-pages-sobre-experiencia-pt-br-js" */),
  "component---src-pages-sobre-pt-br-js": () => import("./../../../src/pages/sobre.pt-br.js" /* webpackChunkName: "component---src-pages-sobre-pt-br-js" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-pages-uses-pt-br-js": () => import("./../../../src/pages/uses.pt-br.js" /* webpackChunkName: "component---src-pages-uses-pt-br-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/Blog/Post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */)
}

